import React from "react";
import "../privacy_statment_components/privacyStatement.css";
import { Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function PrivacyStatementHeader() {
  const theme = createTheme();

  theme.typography.h1 = {
    fontSize: "40px",
    [theme.breakpoints.up("md")]: {
      fontSize: "70px",
    },
  };
  return (
    <div className="headerContainerPr">
      <Grid container className="titlePr">
        <ThemeProvider theme={theme}>
          <Typography variant="h1">PRIVACY STATEMENT CARDZ APP</Typography>
        </ThemeProvider>
      </Grid>
    </div>
  );
}

export default PrivacyStatementHeader;
