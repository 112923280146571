import React, { useEffect } from 'react';
import Header from '../structure_components/Header'
import MainContent from '../structure_components/MainContent'
import Footer from '../structure_components/Footer'

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default Home
