import React from "react";
import Grid from "@mui/material/Grid";
import "../theme/theme.css";
import { Link } from "react-router-dom";
import DownloadButton from "../structure_components/main_content_components/DownloadButton";

function Footer() {
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <div>
      <Grid container direction="column" className="footerContainer">
        <div className="footerButtonContainer">
          <Grid item xs={12} className="footerButton">
          <DownloadButton />
          </Grid>
        </div>
        <Grid item>
          <div className="privacyStatement">
            <Link to="/privacy-statement" className="footerLink">Privacy statement</Link>
          </div>
        </Grid>
        <Grid item>
          <div className="footerDesignBy">
            <p>Copyrights © {date}. Designed by Tim Lokers</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
