import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../theme/mainContent.css";
import img1 from "../../assets/img/screenshot7.png";
import img2 from "../../assets/img/screenshot3.png";
import img3 from "../../assets/img/screenshot1.png";
import img4 from "../../assets/img/screenshot2.png";
import img5 from "../../assets/img/screenshot6.png";
import img6 from "../../assets/img/screenshot4.png";
import img7 from "../../assets/img/screenshot8.png";
import Slider from "react-slick";

import React, { useRef, useState, useEffect } from "react";


function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures this runs only on mount and unmount

  return windowWidth;
}

function ImageCarousel() {

  const windowWidth = useWindowWidth();
  const baseImages = [img1, img2, img3, img4, img5, img6, img7];
  const images = windowWidth > 1200 ? [null, ...baseImages, null, null] : baseImages;

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(3); // start at 1 to skip the first null

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    initialSlide: 3, 
    beforeChange: (current, next) => {
      if (next > 0 && next < images.length - 1) { // only update if not a null slide
        setCurrentSlide(next);
      }
    },
    responsive:  [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 3,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      }
    ]
  };

  const handleClick = (index) => {
    if (index > 0 && index < images.length - 1) { // only go to slide if not a null slide
      sliderRef.current.slickGoTo(index);
    }
  };
  
  return (
    <div className="carousel">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index} className="image-slide">
            {image && <img 
              src={image} 
              alt={`carousel-${index}`} 
              className={(windowWidth < 1200 || currentSlide === index) ? "center" : "side"} 
              onClick={() => handleClick(index)} 
            />}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageCarousel;

