import React from "react";
import Grid from "@mui/material/Grid";
import "../theme/theme.css";
import VerticalLine from "./VerticalLine";
import Welcome from "./main_content_components/Welcome";
import Features from "./main_content_components/Features";
//import ScreenshotCarousel from "./main_content_components/ScreenshotCarousel";
import About from "./main_content_components/About"
import ImageCarousel from "./main_content_components/ImageCarousel"

//!!!! USE THIS AS A DESIGN REFERENCE: https://zozothemes.com/html/layer/demo/index.html

function MainContent() {
  return (
    //Chapter of background story
    <div className="mainContainer">
      <VerticalLine height="40px" />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} sm={11} xs={12} className="mainContentCards">
            <Welcome />
        </Grid>
      </Grid>
      <VerticalLine height="200px" />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} sm={11} xs={12} className="mainContentCards">
          <Features />
        </Grid>
      </Grid>
      <VerticalLine height="200px" />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} sm={11} xs={12} className="mainContentCards">
          <h1>
            App <span style={{ color: "#F4556D" }}>screenshots</span>
          </h1>
          <ImageCarousel />
        </Grid>
      </Grid>
      <VerticalLine height="200px" />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} sm={11} xs={12} className="mainContentCards">
          <About />
        </Grid>
      </Grid>
      <VerticalLine height="40px" />
    </div>

    //Chapter of features
    //Chapter of screenshots
    //Chapter of creator of the app, meet the cardz builders
  );
}

export default MainContent;
