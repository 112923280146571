import React from "react";
import { Grid } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function About() {
  const current = new Date();
  const year = `${current.getFullYear()}`;
  const yearsExperience = year - 2015;

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xl={8}>
        <h1>
          About the <span style={{ color: "#F4556D" }}>creator</span>
        </h1>
        <p>
          Hi Cardz app user! I'm Tim Lokers and have a big heart for IT since I was young, which let me in becoming a software engineer for a few years now ({yearsExperience} years). My passion lies in building mobile & web applications using innovative technology and develop them in such a way that they are robust, future proof and secure. For the enterprise market as well as the consumer market. The story for creating the Cardz app began with the frustration I had of maintaining all these physical retail shopcards in your wallet. Why not digitalize them, I thought, and make my life and others life a bit more easy? I was sure I wasn't the only one that was facing this annoyment of having all these retail shop cards with you. So install the app and join me in a shopping frustration free life!
        </p>
        <div className="linkedInLink">
          <a href="https://www.linkedin.com/in/timlokers/" rel="noreferrer" target="_blank">
            <LinkedInIcon style={{ fontSize: "55px", color: "#0072b1"}} />
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

export default About;
