import React from "react";
import "../privacy_statment_components/privacyStatement.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PrivacyStatementMain() {

  const navigate = useNavigate();

  return (
    <div>
      <Grid container className="mainContentPr" lg={7} xs={12}>
        <Grid className="1">
          <p>
            Cardz is committed to respecting the privacy of our users. This
            Privacy Statement explains our practices regarding the collection,
            use, and protection of your data when using the Cardz app.
          </p>
        </Grid>
        <Grid className="2" >
          <h2>Data Collection and Use</h2>
          <p>
            The Cardz app is designed to work offline and does not require an
            internet connection. The app collects and stores the following types
            of data on your device: - Camera pictures: The app uses your
            device's camera to capture photos. These photos are stored locally
            on your device and are not transmitted or shared with us or any
            third parties. - Non-personal data: The app stores non-personal
            data, such as the name and description of a retail card, given by
            the app user. This data is stored locally on your device and is not
            shared with us or any third parties.
          </p>
        </Grid>
        <Grid className="3">
          <h2>App Permissions</h2>
          <p>
            The Cardz app requests permission to access your device's camera and
            microphone to enable specific features.
          </p>
        </Grid>
        <Grid className="4">
          <h2>Data Storage and Protection</h2>
          <p>
            All data collected by the Cardz app is stored locally on your
            device. We do not have access to this data, and it is not shared
            with us or any third parties. We encourage you to protect your
            device with a password or biometric authentication to prevent
            unauthorized access to your data.
          </p>
        </Grid>
        <Grid className="mainContentLastPr">
          <h2>Third-Party Services</h2>
          <p>
            The Cardz app does not use any third-party services that have access
            to your data. If this changes in the future, we will update this
            Privacy Statement accordingly and notify users within the app.
          </p>
        </Grid>
        <Grid className="mainContentReturn" xl={6} lg={7} md={7} sm={9} xs={10}>
          <button className="returnButton"
          onClick={navigateHome}>
          <p>Bring me back home</p>
          </button>
        </Grid>
      </Grid>
    </div>
  );

  function navigateHome(){
    navigate("/");
  }

}

export default PrivacyStatementMain;
