import React from "react";
import { Grid } from "@mui/material";

function Welcome() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xl={8}>
        <h1>
          Welcome to <span style={{ color: "#F4556D" }}>Cardz</span>
        </h1>
        <p>
          Is your wallet overflowing with too many retail cards and do you no longer have room for a photo of your loved one or family members? With the Cardz app this is history. Store your retail cards by scanning its barcode, this will digitize and store the scanned barcode on your phone immediately. Besides that, as an extra the Cardz app also allows you to store any (discount) coupons. Scan them or make a picture, it will be stored locally on your phone. Have all your retail cards and coupon codes at hand, happy shopping!
        </p>
      </Grid>
    </Grid>

  );
}

export default Welcome;


