import React, { useEffect } from 'react';
import PrivacyStatementHeader from '../structure_components/privacy_statment_components/PrivacyStatementHeader';
import PrivacyStatementMain from '../structure_components/privacy_statment_components/PrivacyStatementMain';
import Footer from '../structure_components/Footer';

function PrivacyStatement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PrivacyStatementHeader />
      <PrivacyStatementMain />
      <Footer />
    </div>
  );
}

export default PrivacyStatement;