import React from "react";
import { Grid } from "@mui/material";
import "../../theme/theme.css";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

function DownloadButton() {

    return (
        <Grid item className="headerContentButtonContainer" xs={12}>
            <div>
                <a href="https://play.google.com/store/apps/details?id=cardz.app" target="_blank" rel="noopener noreferrer">
                    <GooglePlayButton
                        theme={"dark"}
                        className={"custom-style"}
                    />
                </a>
            </div>
            <div className="appstoreButton">
                <AppStoreButton
                    theme={"dark"}
                    className={"custom-style"}
                />
            </div>
        </Grid>
    );
}

export default DownloadButton;
