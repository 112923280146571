import React from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import "../../theme/theme.css";
import "../../theme/mainContent.css";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DiscountIcon from "@mui/icons-material/Discount";
import LanguageIcon from "@mui/icons-material/Language";

function Features() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const responsiveImgStyles = {
    display: isSmallScreen ? "none" : "",
  };

  return (
    <div>
      <h1>
        No nonsense <span style={{ color: "#F4556D" }}>features</span>
      </h1>
      <Grid container className="featureContainer">
        <Grid item lg={4} className="leftFeatureContainer">
          <Grid container className={isSmallScreen ? "featureContainerSmall" : "featureScan"}>
            <Grid item lg={4} className={isSmallScreen ? "featureContainerStretch" : ""}>
              <div className= {isSmallScreen ? "featureIconContainerSmall" : "featureIconContainer"}>
                <QrCodeScannerIcon className="featureIcon"/>
              </div>
            </Grid>
            <Grid item lg={8} className={isSmallScreen ? "featureContainerSmall" : "featureText"}>
              <p>
                Scan the bar- or qr codes of your retail card and store them.
              </p>
            </Grid>
          </Grid>
          <Grid container className={isSmallScreen ? "featureContainerSmall" : "featureOffline"}>
            <Grid item lg={4} className={isSmallScreen ? "featureContainerStretch" : ""}>
              <div className= {isSmallScreen ? "featureIconContainerSmall" : "featureIconContainer"}>
                <WifiOffIcon className="featureIcon"/>
              </div>
            </Grid>
            <Grid item lg={8}>
              <p>
                Offline storage, that makes this app so incredibly fast. No need
                for having an internet connection or user account.
              </p>
            </Grid>
          </Grid>
          <Grid container className={isSmallScreen ? "featureContainerSmall" : "featureDarkMode"}>
            <Grid item lg={4} className={isSmallScreen ? "featureContainerStretch" : ""}>
              <div className= {isSmallScreen ? "featureIconContainerSmall" : "featureIconContainer"}>
                <DarkModeIcon className="featureIcon"/>
              </div>
            </Grid>
            <Grid item lg={8}>
              <p>Want to be fancy? Than you can use the dark theme!</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4}>
          <img
            src={require("../../assets/img/screenshot1.png")}
            alt="phone screenshot"
            style={responsiveImgStyles}
          />
        </Grid>

        <Grid item lg={4} className={isSmallScreen ? "leftFeatureContainer" : ""}>
        <Grid container className={isSmallScreen ? "featureContainerSmall" : "featureDarkMode"}>
            <Grid item lg={4} className={isSmallScreen ? "featureContainerStretch" : ""}>
              <div className= {isSmallScreen ? "featureIconContainerSmall" : "featureIconContainer"}>
              <DiscountIcon className="featureIcon"/>
              </div>
            </Grid>
            <Grid item lg={8}>
              <p>                Have you seen that super cheap coupon code somewhere, make a
                picture and store it!</p>
            </Grid>
          </Grid>
          <Grid container className={isSmallScreen ? "featureContainerSmall" : "featureLanguage"}>
            <Grid item lg={4} className={isSmallScreen ? "featureContainerStretch" : ""}>
              <div className= {isSmallScreen ? "featureIconContainerSmall" : "featureIconContainer"}>
                <LanguageIcon className="featureIcon" />
              </div>
            </Grid>
            <Grid item lg={8} className="featureLanguageText">
              <p>
                Multi language, English, Spanish, Portuguese, French, German &
                Dutch are available.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Features;
