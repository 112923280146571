import React from "react";
import "../theme/theme.css";
import { Grid, Hidden, Box } from "@mui/material";
import DownloadButton from "../structure_components/main_content_components/DownloadButton";

function Header() {
  return (
    <Box className="headerContainer">
      <Grid container className="headerContent" alignItems="center">
        <Grid item xs={12} md={5} className="headerContentLeft">
          <Grid container>
            <Grid item className="headerContentLogo" xs={12}>
              <img
                src={require("../assets/img/CardzLogo_White_Large.png")}
                alt="cardz logo"
              />
            </Grid>
            <Grid item className="headerContentTitle" xs={12}>
              <h1>All your retail shop cards directly at hand!</h1>
            </Grid>
            <Grid item className="headerContentTitle" xs={12}>
              <h2>
                No more searching in your overflowing wallet. With the cardz app
                you can finally keep that nice photo of your loved one in your
                wallet again!
              </h2>
            </Grid>
            <DownloadButton />
          </Grid>
        </Grid>
        <Hidden mdDown>
        <Grid item xs={12} sm={9} md={7} className="headerContentRight">
          <img
            src={require("../assets/img/banner.png")}
            alt="phone screenshot"
            className="responsiveImage"
          />
        </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}

export default Header